import * as components from "@/components";
import useGlobal from "@/hooks/useGlobal";
import { useIsPreview } from "@/hooks/useIsPreview";
import { AnimateIn } from "@/ui/AnimateIn";
import SourceFlowContent from "@sourceflow-uk/sourceflow-content";
import imagesMetaData from "@sourceflow/image_metadata.json";

export default function ({ data, prefix = null, additionalComponents = {} }) {
  const global = useGlobal();
  const isPreview = useIsPreview();

  return (
    <SourceFlowContent
      global={global}
      imagesMetaData={imagesMetaData}
      prefix={prefix}
      data={data ?? []}
      components={{ ...components, ...additionalComponents }}
      ContainerComponent={isPreview ? ({ children }) => <div>{children}</div> : AnimateIn}
    />
  );
}
